import axiosInstance from '../axiosConfig';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_CHANGE_PASSWORD_REQUEST,
    USER_CHANGE_PASSWORD_SUCCESS,
    USER_CHANGE_PASSWORD_FAIL,
} from '../constants/userConstants';

// Helper function for error message extraction
const extractErrorMessage = (error) => {
    return error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message;
};

// Login Action
export const login = (parentEmail, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST });

        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = { parent_email: parentEmail, password };

        const { data } = await axiosInstance.post('/api/users/login/', payload, config);

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: extractErrorMessage(error),
        });
    }
};

// Register Action
// Register Action with corrected payload
export const register = (
    parentEmail, password, name, homeAddress, parentPhoneNumber, 
    passedOutYear, generalConsent, parentalConsent, additionalPurposes, parentName
) => async (dispatch) => {
    try {
        dispatch({ type: USER_REGISTER_REQUEST });

        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = {
            username: parentEmail,
            parent_email: parentEmail,
            password,
            name,  // Use single 'name' field
            home_address: homeAddress,
            parent_phone_number: parentPhoneNumber,
            passed_out_year: passedOutYear,
            general_consent: generalConsent,
            parental_consent: parentalConsent,
            additional_purposes: additionalPurposes,
            parent_name: parentName,
        };

        const { data } = await axiosInstance.post('/api/users/register/', payload, config);

        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: extractErrorMessage(error),
        });
    }
};


// Logout Action
export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    dispatch({ type: USER_LOGOUT });
    window.location.replace('/#/login');
};

// Get User Profile Action
export const getUserProfile = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_PROFILE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };

        const { data } = await axiosInstance.get('/api/users/profile/', config);

        dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload: extractErrorMessage(error),
        });
    }
};

// Update User Profile Action
export const updateUserProfile = (user) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axiosInstance.put('/api/users/profile/update/', user, config);

        const updatedUserInfo = data.token
            ? { ...userInfo, ...data }
            : {
                ...data,
                token: (await axiosInstance.post('/api/token/refresh/', { refresh: userInfo.refresh })).data.access,
                refresh: userInfo.refresh,
            };

        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
        dispatch({ type: USER_LOGIN_SUCCESS, payload: updatedUserInfo });
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILE_FAIL,
            payload: extractErrorMessage(error),
        });
    }
};

// Change Password Action
export const changePassword = (currentPassword, newPassword, confirmPassword) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_CHANGE_PASSWORD_REQUEST });

        const { userLogin: { userInfo } } = getState();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axiosInstance.put(
            '/api/users/change-password/',
            { currentPassword, newPassword, confirmPassword },
            config
        );

        dispatch({ type: USER_CHANGE_PASSWORD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_CHANGE_PASSWORD_FAIL,
            payload: extractErrorMessage(error),
        });
    }
};
