// src/reducers/chapterReducers.js

import {
  CHAPTER_LIST_REQUEST,
  CHAPTER_LIST_SUCCESS,
  CHAPTER_LIST_FAIL,
  SUBCHAPTER_LIST_REQUEST,
  SUBCHAPTER_LIST_SUCCESS,
  SUBCHAPTER_LIST_FAIL,
} from '../constants/chapterConstants';

const initialState = {
  chapters: [],
  loading: false,
  error: null,
};

// Chapter List Reducer
const chapterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAPTER_LIST_REQUEST:
      return { ...state, loading: true, error: null };

    case CHAPTER_LIST_SUCCESS:
      return { ...state, loading: false, chapters: action.payload };

    case CHAPTER_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SUBCHAPTER_LIST_REQUEST:
      // Set loading state for a specific chapter's subchapters
      return {
        ...state,
        chapters: state.chapters.map(chapter =>
          chapter.chapter_id === action.payload
            ? { ...chapter, loadingSubchapters: true }
            : chapter
        ),
      };

    case SUBCHAPTER_LIST_SUCCESS:
      return {
        ...state,
        chapters: state.chapters.map(chapter =>
          chapter.chapter_id === action.payload.chapter_id
            ? { ...chapter, loadingSubchapters: false, subchapters: action.payload.subchapters }
            : chapter
        ),
      };

    case SUBCHAPTER_LIST_FAIL:
      return {
        ...state,
        chapters: state.chapters.map(chapter =>
          chapter.chapter_id === action.payload.chapter_id
            ? { ...chapter, loadingSubchapters: false, subchapterError: action.payload.error }
            : chapter
        ),
      };

    default:
      return state;
  }
};

export default chapterListReducer;
