// src/App.js
import React, { Suspense, lazy } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import './styles.css';

const HomeScreen = lazy(() => import('./screens/HomeScreen'));
const ProductScreen = lazy(() => import('./screens/ProductScreen'));
const DocumentViewerScreen = lazy(() => import('./screens/DocumentViewerScreen'));
const SubtopicDetailsScreen = lazy(() => import('./screens/SubtopicDetailsScreen'));
const LoginScreen = lazy(() => import('./screens/LoginScreen'));
const RegisterScreen = lazy(() => import('./screens/RegisterScreen'));
const UserProfileScreen = lazy(() => import('./screens/UserProfileScreen'));
const ForgotPasswordScreen = lazy(() => import('./screens/ForgotPasswordScreen'));
const ResetPasswordScreen = lazy(() => import('./screens/ResetPasswordScreen'));
const HelpDeskScreen = lazy(() => import('./screens/HelpDeskScreen'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const ManageConsentScreen = lazy(() => import('./screens/ManageConsentScreen')); // New import

function App() {
  return (
    <>
      <Header />
      <main className="py-3">
        <Container>
          <ErrorBoundary>
            <Suspense fallback={
              <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }>
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/profile" element={<UserProfileScreen />} />
                <Route path="/courses/:course_id" element={<ProductScreen />} />
                <Route path="/document/:document_id/view" element={<DocumentViewerScreen />} />
                <Route path="/subchapters/:subchapter_id/subtopics" element={<SubtopicDetailsScreen />} />
                <Route path="/password-reset" element={<ForgotPasswordScreen />} />
                <Route path="/password-reset-confirm/:uidb64/:token" element={<ResetPasswordScreen />} />
                <Route path="/helpdesk" element={<HelpDeskScreen />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/manage-consent" element={<ManageConsentScreen />} /> {/* New route */}
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </Container>
      </main>
      <Footer />
    </>
  );
}

export default App;
