import {
    CONSENT_DETAILS_REQUEST,
    CONSENT_DETAILS_SUCCESS,
    CONSENT_DETAILS_FAIL,
    CONSENT_UPDATE_REQUEST,
    CONSENT_UPDATE_SUCCESS,
    CONSENT_UPDATE_FAIL,
} from '../constants/consentConstants';

export const consentDetailsReducer = (state = { consent: {} }, action) => {
    switch (action.type) {
        case CONSENT_DETAILS_REQUEST:
            return { loading: true, ...state };
        case CONSENT_DETAILS_SUCCESS:
            return { loading: false, consent: action.payload };
        case CONSENT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const consentUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSENT_UPDATE_REQUEST:
            return { loading: true };
        case CONSENT_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case CONSENT_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
