import React, { useState, useCallback } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer() {
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  return (
    <footer className="footer" role="contentinfo">
      <Container>
        <Row>
          <Col className="text-center py-3">
            <p>
              <a 
                href="https://merchant.razorpay.com/policy/OPsBghOhoS8Ar6/terms" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="footer-link"
              >
                Terms and Conditions
              </a>
              {' | '}
              <a 
                href="https://merchant.razorpay.com/policy/OPsBghOhoS8Ar6/refund" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="footer-link"
              >
                Cancellation and Refund
              </a>
              {' | '}
              <a 
                href="https://merchant.razorpay.com/policy/OPsBghOhoS8Ar6/shipping" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="footer-link"
              >
                Shipping and Delivery
              </a>
              {' | '}
              <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
              {' | '}
              <Link to="/manage-consent" className="footer-link">Manage Your Consent</Link>
              {' | '}
              <button 
                onClick={handleShow} 
                className="btn-link footer-link" 
                aria-label="Contact Us"
              >
                Contact Us
              </button>
            </p>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Address:</strong></p>
            <p>1-247, Main Road, Library Center, Nidamarru, Guntur, Andhra Pradesh, 522503</p>
            <p><strong>Email:</strong> <a href="mailto:support@min-elearn.com" className="footer-link">support@min-elearn.com</a></p>
            <p><strong>Phone:</strong> <a href="tel:+917305860217" className="footer-link">+91-7305860217</a></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </footer>
  );
}

export default Footer;
