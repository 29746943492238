import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { combineReducers } from 'redux';
import {
  courseListReducer,
  courseDetailsReducer,
} from './reducers/courseReducers';
import chapterListReducer from './reducers/chapterReducers';
import subtopicListReducer from './reducers/subtopicReducers';
import {
  userLoginReducer,
  userRegisterReducer,
  userProfileReducer,
  userUpdateProfileReducer,
  userChangePasswordReducer,
} from './reducers/userReducers';
import { textDocumentListReducer } from './reducers/textDocumentReducers';
import { consentDetailsReducer, consentUpdateReducer } from './reducers/consentReducers';

// Redux Persist Configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Root reducer
const rootReducer = combineReducers({
  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  chapterList: chapterListReducer,
  subtopicList: subtopicListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userProfile: userProfileReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userChangePassword: userChangePasswordReducer,
  textDocumentList: textDocumentListReducer,
  consentDetails: consentDetailsReducer,
  consentUpdate: consentUpdateReducer,
});

// Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Fetch userInfo from local storage to initialize state if present
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

// Set initial state with user info
const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

// Configure Redux store with reducers, middleware, and preloaded state
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
	  thunk,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
  preloadedState: initialState,
});

const persistor = persistStore(store);

export { store, persistor };
