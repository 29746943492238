// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Add this line
import { HashRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store, persistor } from './store'; // Import persistor
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Creating root element for rendering the app
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}> {/* Add PersistGate here */}
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>
);

// Measure performance in your app, pass a function to log results (for example: reportWebVitals(console.log))
reportWebVitals();
