// src/constants/chapterConstants.js

export const CHAPTER_LIST_REQUEST = 'CHAPTER_LIST_REQUEST';
export const CHAPTER_LIST_SUCCESS = 'CHAPTER_LIST_SUCCESS';
export const CHAPTER_LIST_FAIL = 'CHAPTER_LIST_FAIL';
// src/constants/subchapterConstants.js

export const SUBCHAPTER_LIST_REQUEST = 'SUBCHAPTER_LIST_REQUEST';
export const SUBCHAPTER_LIST_SUCCESS = 'SUBCHAPTER_LIST_SUCCESS';
export const SUBCHAPTER_LIST_FAIL = 'SUBCHAPTER_LIST_FAIL';
