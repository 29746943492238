import {
    TEXT_DOCUMENT_LIST_REQUEST,
    TEXT_DOCUMENT_LIST_SUCCESS,
    TEXT_DOCUMENT_LIST_FAIL,
  } from '../constants/textDocumentConstants';
  
  // Reducer for handling the state of text document list
  export const textDocumentListReducer = (state = { textDocuments: [] }, action) => {
    switch (action.type) {
      case TEXT_DOCUMENT_LIST_REQUEST:
        return { ...state, loading: true }; // Preserve existing documents while loading
  
      case TEXT_DOCUMENT_LIST_SUCCESS:
        const newDocuments = action.payload;
  
        // Merge new documents with existing ones, avoiding duplicates
        const mergedDocuments = [
          ...state.textDocuments.filter(
            (doc) =>
              !newDocuments.some(
                (newDoc) => newDoc.document_id === doc.document_id
              )
          ),
          ...newDocuments,
        ];
  
        return { loading: false, textDocuments: mergedDocuments };
  
      case TEXT_DOCUMENT_LIST_FAIL:
        return { ...state, loading: false, error: action.payload }; // Preserve existing documents on error
  
      default:
        return state; // Default case returns the current state unchanged
    }
  };
  