import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
} from '../constants/courseConstants';

// Initial state for course list
const initialCourseListState = { courses: [], loading: false, error: null };

// Initial state for course details
const initialCourseDetailsState = { course: null, loading: false, error: null };

// Course list reducer
const courseListReducer = (state = initialCourseListState, action) => {
  switch(action.type){
    case COURSE_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case COURSE_LIST_SUCCESS:
      return { loading: false, courses: action.payload, error: null };
    case COURSE_LIST_FAIL:
      return { loading: false, courses: [], error: action.payload };
    default:
      return state;
  }
};

// Course details reducer
const courseDetailsReducer = (state = initialCourseDetailsState, action) => {
  switch (action.type) {
    case COURSE_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case COURSE_DETAILS_SUCCESS:
      return { loading: false, course: action.payload, error: null };
    case COURSE_DETAILS_FAIL:
      return { loading: false, course: null, error: action.payload };
    default:
      return state;
  }
};

export { courseListReducer, courseDetailsReducer };
