import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';
import logo from '../../src/images/My_logo.png';

function Header() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const location = useLocation();

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <header>
      <Navbar expand="lg" variant="dark" collapseOnSelect className="header-navbar">
        <Container fluid>
          <Navbar.Brand as={Link} to="/home">
            <img src={logo} width="50" height="50" className="d-inline-block align-top logo" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink
                to="/home"
                className={`nav-link nav-link-common ${location.pathname === '/home' ? 'active' : ''}`}
              >
                <i className="fas fa-home fa-lg"></i> <span className="nav-text">Home</span>
              </NavLink>
            </Nav>
            <Nav className="ml-auto">
              <NavLink
                to="/helpdesk"
                className={`nav-link nav-link-common ${location.pathname === '/helpdesk' ? 'active' : ''}`}
              >
                <i className="fas fa-question-circle fa-lg"></i> <span className="nav-text">Help Desk</span>
              </NavLink>
              {userInfo ? (
                <NavDropdown title={<span className="nav-text-bold"><i className="fas fa-user-circle"></i> {userInfo.name}</span>} id="username" className="text-white">
                  <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavLink
                  to="/login"
                  className={`nav-link nav-link-common ${location.pathname === '/login' ? 'active' : ''}`}
                >
                  <i className="fas fa-user fa-lg"></i> <span className="nav-text">Login</span>
                </NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
